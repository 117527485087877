import React, { ReactElement } from 'react';
import './Main.scss';

import Hand from '../../assets/images/hand_3.png';

import Register from '../../components/register/Register';
import Header from '../../components/header/Header';
import { useTranslation } from 'react-i18next';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <section className="header">
        <Header />
      </section>
      {/* <section className="prizes container">
        <img src={Prizes} alt="" />
      </section> */}
      <section className="register-form container">
        <div className="columns">
          <div className="column">
            <Register />
          </div>
        </div>
      </section>
      <section className="footer">
        <img src={Hand} alt="" />
        <div className="footer-text">
          <span>{t('footer1')}</span>
          <span>{t('footer2')}</span>
          <span>{t('footer3')}</span>
        </div>
      </section>
    </div>
  );
};

export default Main;
