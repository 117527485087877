import React, { ReactElement } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Thanks from './views/Thanks/Thanks';
import Main from './views/Main/Main';
// import usePageTracking from './components/page-tracking/usePageTracking';

const App: React.FC = (): ReactElement => {
  // usePageTracking();
  return (
    <div className="App">
      {/* <Main /> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
      <div className="cookie-container">
        <div id="consent_blackbar"></div>
        <div id="teconsent"></div>
      </div>
    </div>
  );
};

export default App;
