import React, { ReactElement } from 'react';
import './Rules.scss';

const Lv: React.FC = (): ReactElement => {
  return (
    <div className="Lv rules-wrapper">
      <h2>”Cheetos 2024” loterijas noteikumi</h2>
      <ol>
        <li>
          ”Cheetos 2024” ir Latvijā notiekoša patērētāju loterija, kuru rīko
          “Cheetos” zīmola preču izplatītājs Latvijā - SIA "MV GROUP
          Distribution LV", adrese: Medus iela 7, Rīga, LV-1048, vienotais
          reģistrācijas numurs 40003787568, turpmāk tekstā - Loterijas
          Organizētājs.
        </li>
        <li>
          Loterijas norises teritorija – jebkura tirdzniecības vieta visā
          Latvijas Republikas teritorijā, kurā tiek izplatītas un ir iespējams
          iegādāties Loterijas preces.
        </li>
        <li>
          Loterijā Latvijas Republikas teritorijā var piedalīties ikviena
          persona, bez vecuma ierobežojuma, kura iegādājas Loterijas Preci.
        </li>
        <li>
          Loterijas norises kārtību saskaņā ar šiem noteikumiem nosaka Loterijas
          Organizētājs, saskaņojot tos ar Latvijas Republikas Izložu un
          azartspēļu uzraudzības inspekciju, un to izpilde un ievērošana ir
          obligāta visiem Loterijas dalībniekiem.
        </li>
        <li>
          Loterijas norises sākums ir 2024.gada 15.janvāris un beigu datums ir
          2024.gada 26.februāris.
          <ol>
            <li>
              Periods, kurā iespējams nopirkt Loterijas preces un pieteikt savu
              dalību Loterijā ir no 2024.gada 15.janvāra plkst.00.00 līdz
              2024.gada 25.februāra plkst.23.59. Reģistrācija, kura tiek veikta
              pirms vai pēc iepriekš minētā perioda, tiek uzskatīta par nederīgu
              un nepiedalās laimestu izlozēs.
            </li>
          </ol>
        </li>
        <li>
          Loterijas preces ir jebkura “Cheetos” zīmola prece – sāļā uzkoda, kas
          nopērkama Latvijas Republikas teritorijā.
        </li>
        <li>
          Loterijas preču skaits, kuras iegādājoties var piedalīties Loterijā,
          ievērojot saprātīgu pieprasījumu, netiks ierobežots. Loterijas preces
          var būt pieejamas tirdzniecībā arī pirms vai pēc šo noteikumu
          5.1.punktā norādītā perioda, taču to iegāde pirms vai pēc šajos
          noteikumos norādītā termiņa nedod tiesības piedalīties Loterijā.
        </li>
        <li>
          <strong>Loterijas norises kārtība: </strong>
          <ol>
            <li>
              <strong>
                Lai piedalītos Loterijā patērētājam jāiegādājas šo noteikumu
                6.punktā norādītā “Cheetos” zīmola prece. Patērētājam jāsaglabā
                pirkuma čeks un jāreģistrē pirkuma čeka numurs interneta vietnē{' '}
              </strong>
              <a href="http://www.cheetos.lv/">
                <strong>www.cheetos.lv </strong>
              </a>
            </li>
            <li>
              Reģistrējot pirkuma čeka numuru interneta vietn
              <a href="http://www.laysloterija.lv/">ē </a>
              <a href="http://www.laysloterija.lv/">
                <u>www.cheetos.lv </u>
              </a>{' '}
              , anketā Loterijas dalībniekam jānorāda vārds, uzvārds, čeka
              numurs, dalībnieka tālruņa numurs un e-pasta adrese. Ja
              reģistrēšanās interneta vietnē{' '}
              <a href="http://www.laysloterija.lv/">
                <u>www.cheetos.lv </u>
              </a>{' '}
              notiek veiksmīgi, ierīces monitorā tiek parādīts apstiprinošs
              paziņojums. Neveiksmīgas reģistrācijas gadījumā Loterijas
              dalībnieks par to tiek informēts, dodot iespēju labot datus līdz
              brīdim, kad reģistrācija ir veiksmīga, pretējā gadījumā Loterijas
              dalībnieks netiks reģistrēts dalībai Loterijā.
            </li>
            <li>
              Reģistrējoties Loterijai šo noteikumu 8.punktā un tā apakšpunktos
              norādītajā veidā, Loterijas dalībnieks uzņemas pilnu atbildību par
              pareizas un patiesas informācijas norādīšanu, par tās sastādīšanas
              precizitāti un kļūdām, norādot anketā pieprasīto informāciju.
            </li>
            <li>
              Loterijas dalībniekam jāsaglabā Loterijas preces pirkuma čeks, jo
              tas kalpo par Loterijas preces iegādes apliecinājumu un
              reģistrācijas identifikāciju, kā arī ir nosacījums laimesta
              saņemšanai.
            </li>
          </ol>
        </li>
        <li>
          Viens Loterijas dalībnieks var reģistrēties Loterijai vairākkārt tikai
          pēc atkārtota Loterijas preču pirkuma, reģistrējot jaunu pirkuma čeka
          numuru.
          <ol>
            <li>
              Ja viena persona mēģina reģistrēt vienu čeku vairākkārt, tādā
              veidā mēģinot palielināt savas izredzes laimēt, Loterijas
              Organizētājs patur tiesības, atsevišķi neinformējot šo Loterijas
              dalībnieku par čeka numura neunikalitāti, anulēt šīs
              reģistrācijas, tādā veidā nodrošinot izlozi, ievērojot
              vienlīdzības un godīgas dalības principus.
            </li>
          </ol>
        </li>
        <li>
          Kad Loterijas dalībnieks piereģistrē savu iegūto čeka numuru, tas
          piedalās nākošajā izlozē pēc tā reģistrācijas, kas notiek tuvākajā
          datumā, kas minēts šo noteikumu 12.punktā.
        </li>
        <li>
          Laimestu fonds - 6 spēļu konsoles “SONY” Playstation 5 CD reader ar
          spēli “Call of Duty Modern Warfare 3”, ar kopējo vērtību 3048.00 (trīs
          tūkstoši četrdesmit astoņi) euro:
        </li>
         
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Laimesta nosaukums </strong>
              </td>
              <td>
                <strong>Laimesta vērtība, ieskaitot PVN (euro) </strong>
              </td>
              <td>
                <strong>Skaits </strong>
              </td>
              <td>
                <strong>Kopējā summa, ieskaitot PVN (euro) </strong>
              </td>
            </tr>
            <tr>
              <td>
                Spēļu konsole “SONY” Playstation 5 CD reader ar spēli “Call of
                Duty Modern Warfare 3”
              </td>
              <td>508.00</td>
              <td>6</td>
              <td>3048.00</td>
            </tr>
            <tr>
              <td> </td>
              <td> </td>
              <td>Kopā:</td>
              <td>3048.00</td>
            </tr>
          </tbody>
        </table>
        <span>
          Kopā 6 (sešas) balvas ar kopējo vērtību 3048.00 EUR (trīs tūkstoši
          četrdesmit astoņi euro), ieskaitot PVN (pievienotās vērtības
          nodoklis).
        </span>
        <li>
          Tiek noteiktas sešas izlozes, kurās piedalās saskaņā ar šo noteikumu
          5.1.punktu, kā arī 8.punktu un tā apakšpunktiem saņemtās čeka numuru
          reģistrācijas, un tieši:
          <ol>
            <li>
              izloze notiek 2024.gada 22.janvārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2024.gada 15.janvāra plkst.00:00 līdz 2024.gada
              21.janvārim plkst.23:59, izlozējot vienu Spēļu konsoli “SONY”
              Playstation 5 CD reader ar spēli “Call of Duty Modern Warfare 3”;
            </li>
            <li>
              izloze notiek 2024.gada 29.janvārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2024.gada 22.janvāra plkst.00:00 līdz 2024.gada
              28.janvārim plkst.23:59, izlozējot vienu Spēļu konsoli “SONY”
              Playstation 5 CD reader ar spēli “Call of Duty Modern Warfare 3”;
            </li>
            <li>
              izloze notiek 2024.gada 05.februārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2024.gada 29.janvāra plkst.00:00 līdz 2024.gada
              04.februāra plkst.23:59, izlozējot vienu Spēļu konsoli “SONY”
              Playstation 5 CD reader ar spēli “Call of Duty Modern Warfare 3”;
            </li>
            <li>
              izloze notiek 2024.gada 12.februārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2024.gada 05.februāra plkst.00:00 līdz 2024.gada
              11.februārim plkst.23:59, izlozējot vienu Spēļu konsoli “SONY”
              Playstation 5 CD reader ar spēli “Call of Duty Modern Warfare 3”;
            </li>
            <li>
              izloze notiek 2024.gada 19.februārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2024.gada 12.februāra plkst.00:00 līdz 2024.gada
              18.februārim plkst.23:59, izlozējot vienu Spēļu konsoli “SONY”
              Playstation 5 CD reader ar spēli “Call of Duty Modern Warfare 3”;
            </li>
            <li>
              izloze notiek 2024.gada 26.februārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2024.gada 19.februāra plkst.00:00 līdz 2024.gada
              25.februārim plkst.23:59, izlozējot vienu Spēļu konsoli “SONY”
              Playstation 5 CD reader ar spēli “Call of Duty Modern Warfare 3”.
            </li>
          </ol>
        </li>
        <li>
          Visas izlozes tiek rīkotas Loterijas Organizētāja pilnvarotās personas
          telpās – SIA “Sorbum LV” birojā, pēc adreses: Jūrkalnes iela 15/25,
          Rīga, katras attiecīgās dienas plkst.14:00.
        </li>
        <li>
          Gadījumā, ja izlozētie laimētāji saskaņā ar šiem noteikumiem nav
          tiesīgi saņemt balvu (t.sk. laimētājs nespēj pierādīt savu dalību
          Loterijā vai čeka numura reģistrācija loterijai nav veikta atbilstoši
          šiem noteikumiem), tad izlozētais laimests paliek Loterijas
          Organizētāja īpašumā un rīcībā, un Loterijas Organizētājs ir tiesīgs
          rīkoties ar to pēc saviem ieskaitiem, tai skaitā nodot to cita
          Loterijas Dalībnieka īpašumā pēc savas izvēles.
        </li>
        <li>
          Katrā izlozē katram laimestam tiek noteikti rezerves laimētāji, kuru
          vārdi netiek publicēti, ar kuriem Loterijas Organizētāja pilnvarotā
          persona – SIA “Sorbum LV” nepieciešamības gadījumā sazinās pēc
          Loterijas norises beigu datuma, ja laimestu ieguvēji nav ieradušies
          pakaļ saviem laimestiem, un kuriem Loterijas Organizētājs šādā
          gadījumā, saskaņā ar Loterijas noteikumu 13.punkta noteikumiem, ir
          tiesīgs nodot attiecīgo Loterijas laimestu.
        </li>
        <li>
          Kopējais Loterijas dalībnieku skaits nav iepriekš zināms, bet tiek
          prognozēts, ka izlozēm savu dalību pieteiks vienāds dalībnieku skaits
          reģistrējot unikālos čeka numurus. Aptuvenās izredzes laimēt ir 6 pret
          7000.
        </li>
        <li>
          Loterijas dalībnieku un unikālo kodu reģistrāciju, kā arī izlozes
          norisi un tās veikšanu nodrošina Loterijas Organizētāja pilnvarotā
          persona – SIA “Sorbum LV”, vienotais reģistrācijas Nr.40103605552, ar
          saviem vai apakšuzņēmēju spēkiem un resursiem.
        </li>
        <li>
          Informācija un personas dati, ko Loterijas dalībnieki iesniedz
          reģistrējoties dalībai Loterijā, tiek apkopoti, apstrādāti un
          uzglabāti saskaņā ar Latvijas Republikā spēkā esošo likumdošanas un
          normatīvo aktu prasībām attiecībā uz personas datu aizsardzību.
        </li>
        <li>
          Reģistrējoties Loterijai, Loterijas dalībnieki apliecina, ka piekrīt
          šiem noteikumiem un, ka Loterijas Organizētājs un tā pilnvarotās
          personas drīkst apstrādāt, uzglabāt un izmantot viņu sniegtos personas
          datus laimestu ieguvēju noteikšanai, to identifikācijai, publicēšanai
          interneta vietnē <a href="http://www.cheetos.lv/">www.cheetos.lv</a>
          <a href="http://www.laysloterija.lv/">,</a> un laimestu izsniegšanai.
          Datu apstrādes mērķis ir nodrošināt laimestu ieguvēju identifikāciju
          un laimestu izsniegšanu.
        </li>
        <li>
          Iesniedzot vai reģistrējot savus personas datus dalībai Loterijā,
          Loterijas dalībnieki piekrīt, ka dati tiek apstrādāti un uzglabāti
          visu Loterijas laiku, kā arī pēc Loterijas noslēguma tiek saglabāti
          Loterijas Organizētāja datu bāzēs tik ilgi, cik tas nepieciešams
          saskaņā ar piemērojamiem normatīviem aktiem.
        </li>
        <li>
          Katras izlozes laimestu ieguvēji tiek publicēti interneta vietnē{' '}
          <a href="http://www.cheetos.lv/">www.cheetos.lv</a>
          <a href="http://www.laysloterija.lv/">,</a> līdz nākamās darba dienas,
          pēc izlozes dienas, beigām.
        </li>
        <li>
          Visi laimestu ieguvēji jeb pilns uzvarētāju saraksts tiek publicēts
          interneta vietnē <a href="http://www.cheetos.lv/">www.cheetos.lv</a>
          <a href="http://www.laysloterija.lv/">,</a> līdz 2024.gada 26.februāra
          dienas beigām.
        </li>
        <li>
          Laimesti tiek uzglabāti pie Loterijas Organizētāja pilnvarotās
          personas – SIA “Sorbum LV”, birojā pēc adreses: Jūrkalnes iela 15/25,
          Rīga, un tie tiek izsniegti laimestu ieguvējiem personīgi, saskaņā ar
          šo noteikumu prasībām.
        </li>
        <li>
          Lai vienotos par laimestu izsniegšanu, SIA “Sorbum LV” izlozes dienā
          pēc izlozes sazinās ar laimestu ieguvējiem, rakstot uz anketā norādīto
          e-pasta adresi, nosūtot īsziņu vai piezvanot uz norādīto tālruņa
          numuru.
        </li>
        <li>
          Lai saņemtu laimestu, laimesta ieguvējam jāuzrāda personu apliecinošs
          dokuments (pase vai personas apliecība (eID)) un jāuzrāda laimējušais
          čeks Loterijas Organizētāja pilvarotajai personai – SIA “Sorbum LV” un
          jāļauj nokopēt laimējušo čeku un saglabāt tā kopiju.
        </li>
        <li>
          Ja laimesta ieguvējs nevar uzrādīt kādu no šajos noteikumos norādītiem
          personu apliecinošiem dokumentiem un/vai laimējušā čeka oriģinālu vai
          dokumentos esošā informācija nesakrīt ar reģistrācijā Loterijai
          norādīto informāciju, Loterijas Organizētājs vai tā pilnvarotā persona
          ir tiesīgs laimestu neizsniegt.
        </li>
        <li>
          Ja Loterijā reģistrētais un izlozē laimējušais čeka numurs nav
          identisks uzrādītā čekā norādītajam čeka numuram laimestu saņemot, tai
          skaitā, simbolu kombinācija un secība precīzi neatbilst, čeka numurs
          nesatur tieši tos pašus simbolus, tam pievienoti papildu simboli vai
          kādi no simboliem aizvietoti ar līdzīgiem, vai Loterijas Organizētājam
          rodas aizdomas par krāpšanas mēģinājumu, Loterijas Organizētājs ir
          tiesīgs laimesta ieguvējam laimestu neizsniegt.
        </li>
        <li>
          Ja laimesta ieguvējs ir nepilngadīga persona, laimestu var saņemt viņa
          likumiskais pārstāvis, uzrādot laimesta ieguvēja un savu personu
          apliecinošu dokumentu (pasi vai personas apliecību (eID)) un čeka
          oriģinālu.
        </li>
        <li>
          Loterijas Organizētājs nodrošina, ka laimestus to ieguvēji varēs
          personiski saņemt līdz 2024.gada 26.martam šo noteikumu 22.punktā
          norādītā adresē. Gadījumā, ja valstī būs noteikta Ārkārtas situācija,
          laimesta saņemšana pēc šo noteikumu 24.punktā un tā apakšpunktos
          noteikto prasību izpildes būs iespējama izmantojot pasta vai
          kurjerpasta pakalpojumus, kurus segs Loterijas Organizētājs.
        </li>
        <li>
          Ja laimestu ieguvēji šo noteikumu 28.punktā norādītajā termiņā nav
          ieradušies pēc laimestiem, laimestu ieguvēji zaudē savu laimesta
          ieguvēja statusu, un laimesti bez jebkādas kompensācijas pāriet
          Loterijas Organizētāja īpašumā, tai skaitā laimestu ieguvējiem šādā
          gadījumā nav tiesības pretendēt uz laimesta vērtības atlīdzināšanu
          naudā.
        </li>
        <li>
          Saņemot laimestus, laimestu ieguvēji un Loterijas Organizētāja
          pilnvarotā persona - SIA “Sorbum LV”, paraksta laimesta
          pieņemšanas-nodošanas aktu. Pēc laimesta saņemšanas un
          pieņemšanas-nodošanas akta parakstīšanas, laimestu ieguvēji nevar
          vērsties pie Loterijas Organizētāja ar pretenziju par laimestu.
        </li>
        <li>
          Loterijas Organizētājs neatbild par laimestu ieguvējam iespējami
          radītiem zaudējumiem, kas radušies, lietojot laimestu.
        </li>
        <li>
          Laimesta apmaiņa pret citu laimestu vai tās vērtības samaksa skaidrā
          naudā nav iespējama.
        </li>
        <li>
          Ar dalību izlozē vai laimesta saņemšanu saistītie papildu izdevumi,
          kas nav norādīti šajos noteikumos, piemēram, transporta izdevumi, datu
          pārraides izmaksas vai telefona sarunu izmaksas, laimestu ieguvējiem
          un Loterijas dalībniekiem netiek atlīdzināti.
        </li>
        <li>
          Loterijas Organizētājs nav atbildīgs, ja Loterijas dalībnieki neievēro
          šos noteikumus.
        </li>
        <li>
          Loterijas Organizētājs neuzņemas atbildību par Loterijas dalībnieku
          izslēgšanu no izlozes, kā arī par laimesta neizsniegšanu gadījumos,
          kad nav ievēroti šie noteikumi vai Loterijas dalībnieku sniegtā
          informācija ir nepatiesa vai neprecīza, vai, ja ar laimestu ieguvējiem
          nav izdevies sazināties no Loterijas Organizētāja neatkarīgu iemeslu
          dēļ.
        </li>
        <li>
          Visas pretenzijas par Loterijas rīkošanu un/vai norisi ir jānosūta
          rakstiski Loterijas Organizētājam – SIA “MV GROUP Distribution LV”,
          pēc adreses: Medus iela 7, Rīga, Latvija, LV-1048, vai elektroniski uz
          epasta adresi: <u>birojs@mvgroup.eu</u> (elektroniski parakstītas ar
          drošu elektonisko parakstu un satur laika zīmogu) līdz 2024.gada
          26.martam, ar norādi “Cheetos 2024” loterija, pilnībā izklāstot
          iebildumus un to pamatojumu, kā arī pievienojot dokumentus vai to
          kopijas, uz kurām ir atsauces pretenzijā.
        </li>
        <li>
          Pretenzijas, kas iesniegtas pēc 2024.gada 26.marta, netiks izskatītas
          un neradīs pusēm jebkādas juridiskas sekas.
        </li>
        <li>
          Loterijas Organizētājs izskata saņemto pretenziju un atbild uz to 15
          (piecpadsmit) darba dienu laikā pēc tās saņemšanas. Ja Loterijas
          Organizētājs uzskata pretenziju par pamatotu, tas atbildē norāda
          pretenzijas apmierināšanas kārtību un termiņu. Ja pretenzijas
          iesniedzēju neapmierina Loterijas Organizētāja sniegtā atbilde,
          persona var aizsargāt savas tiesības Latvijas Republikas tiesību
          normās noteiktajā kārtībā.
        </li>
        <li>
          Datums pasta zīmogā uz aploksnes nosūtītajām pretenzijām ir uzskatāms
          par pierādījumu pretenzijas iesniegšanas termiņa noteikšanai, bet
          elektroniski nosūtītajām pretenzijām termiņš tiek noteikts pēc
          pretenzijas saņemšanas datuma Loterijas Organizētāja norādītajā
          e-pasta adresē.
        </li>
        <li>
          Visās pretenzijās to iesniedzējam ir jānorāda Loterijas dalībnieka
          vārds, uzvārds un precīza adrese.
        </li>
        <li>
          Loterijas Organizētāja, tā pilnvaroto personu un Loterijas dalībnieku
          tiesības un pienākumi ir noteikti tikai šajos noteikumos.
        </li>
        <li>
          Loterijas Organizētājs paziņo un brīdina, ka reklāmas materiālos
          pieejamajai informācijai par Loteriju ir tikai informatīvs raksturs.
        </li>
        <li>
          Ar Izložu un azartspēļu uzraudzības inspekcijas apstiprinātu šo
          noteikumu redakciju ir iespējams iepazīties interneta vietnē{' '}
          <a href="http://www.cheetos.lv/">www.cheetos.lv</a>
          <a href="http://www.laysloterija.lv/">.</a>
        </li>
      </ol>
         
      <em>
        * Dokuments parakstīts elektroniski ar drošu elektronisko parakstu un
        satur laika zīmogu.{' '}
      </em>
    </div>
  );
};

export default Lv;
