import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Cheetose PlayStationi kampaania 2024</h2>

      <ol>
        <li>
          Tarbijakampaania „Cheetose PlayStationi kampaania 2024“ on Eestis
          toimuv kampaania, mida korraldab Cheetos maisikrõpsude edasimüüja
          Eestis – Pepsico Eesti AS (edaspidi kampaania korraldaja),
          registrinumber 10438708, Tuglase 14, 51006 Tartu. Loosimist korraldab
          Direct Messenger OÜ, registrinumber 11981389, Mustamäe tee 44/1, 10621
          Tallinn.
        </li>
        <li>
          Kampaania territoorium – kõik kauplused Eesti Vabariigi
          territooriumil, kus kampaanias osalevaid tooteid müüakse.
        </li>
        <li>Kampaanias võivad osaleda igas vanuses Eesti elanikud.</li>
        <li>Kampaania leiab aset 15. jaanuar kuni 25. veebruar 2024 (k.a).</li>
        <li>Kampaanias osalevad kõik Cheetose tooted.</li>
        <li>
          Jaemüügis saadaolevad ja kampaanias osalevate toodete arv ei ole
          piiratud. Arvestatakse mõistliku nõudlusega. Ostes kauplustest Cheetos
          tooteid enne või pärast käesolevate tingimuste punktis 4 toodud
          kuupäevi, ei anna õigust kampaanias osaleda.
        </li>
        <li>
          <strong>Kampaania põhimõte</strong>
          <ol>
            <li>
              <strong>
                Kampaanias osalemiseks peab tarbija ostma kauplusest vähemalt
                ühe Cheetose toote ja{' '}
              </strong>
              <strong>registreerima ostutšeki veebilehel </strong>
              <a href="http://www.cheetos.ee/">
                <u>
                  <strong>www.cheetos.ee</strong>
                </u>
              </a>
              <strong>. </strong>
              <strong>Osalejate vahel loositaks</strong>
              <strong>
                e välja 6 (kuus) Sony PlayStation 5 mängukonsooli.
              </strong>
            </li>
            <li>
              Registreerides ostutšeki veebilehel <u>www.cheetos.ee</u> tuleb
              ära märkida loosis osaleja eesnimi, perekonnanimi, ostutšeki
              number, telefoninumber ja e-posti aadress. Registreerimine
              veebilehel{' '}
              <a href="http://www.cheetos.ee/">
                <u>www.cheetos.ee </u>
              </a>{' '}
              on tasuta. Kui osalejal ei õnnestu ostutšekil olevat numbrit
              edukalt registreerida, ilmub lehele veateade.
            </li>
            <li>
              Alaealine registreeruja võib teha veebilehel{' '}
              <a href="http://www.cheetos.ee/">
                <u>www.cheetos.ee </u>
              </a>{' '}
              registreerimise, tema seadusliku esindaja nõusolekul, esindaja
              meiliaadressi ja isikuandmetega.
            </li>
            <li>
              Registreerides ostutšekil oleva numbri vastavalt käesolevate
              eeskirjade punktile 7.2., võtab kampaanias osaleja vastutuse
              korrektsete isikuandmete esitamise ja nende esitamisel tehtud
              vigade eest.
            </li>
            <li>
              <strong>
                Kampaanias osaleja hoiab registreeritud ostutšeki alles kuni
                25.03.2024., et tõestada kampaanias osaleva toote ostmist.
              </strong>
            </li>
            <li>
              Ühte ostutšeki numbrit saab kogu kampaania toimumise ajal
              registreerida ühe korra.
            </li>
            <li>
              Üks ja sama osaleja võib teha mitu registreerimist, kui ostab
              kampaanias osalevaid tooteid mitu korda ja registreerib iga kord
              uue ostutšeki numbri.
              <ol>
                <li>
                  Juhul, kui üks ja sama isik üritab sama ostutšeki numbrit mitu
                  korda sisestada, osaleb loosimises ainult esimene korrektselt
                  tehtud registreering, kõik korduvad tühistatakse.
                </li>
                <li>
                  Vaheauhinna võitnud tšekk ei osale teist korda peaauhinna
                  loosimises. Ühe tšekiga saab võita ühe korra.
                </li>
                <li>
                  Sama osaleja võib võita mitu korda, kui on registreeritud mitu
                  erinevat ostutšekki.
                </li>
              </ol>
            </li>
            <li>
              Pärast ostutšeki registreerimist osaleb see auhinna loosimises.
              Loosimiste kuupäevad on välja toodud punktis 8.2.
            </li>
          </ol>
        </li>
        <li>
          <strong>Auhinnafond</strong>
          <ol>
            <li>Auhinnafondi koguväärtus on 3534 €.</li>
            <li>
              Auhindadeks on kuus Sony PlayStation 5 mängukonsooli (ühe konsooli
              väärtus on 589 €).Auhindade loosimised toimuvad kord nädalas
              järgnevatel kuupäevadel:
              <ol>
                <li>
                  22.01.2024 kell 12:00, mil loositakse välja üks Sony
                  PlayStation 5 mängukonsool. Loosis osalevad kõik tšekid, mis
                  on registreeritud ajavahemikus 15.01.2024 kell 00:00:00 kuni
                  21.01.2023 kell 23:59:59.
                </li>
                <li>
                  29.01.2024 kell 12:00, mil loositakse välja üks Sony
                  PlayStation 5 mängukonsool. Loosis osalevad kõik tšekid, mis
                  on registreeritud ajavahemikus 22.01.2024 kell 00:00:00 kuni
                  28.01.2024 kell 23:59:59.
                </li>
                <li>
                  5.02.2024 kell 12:00, mil loositakse välja üks Sony
                  PlayStation 5 mängukonsool. Loosis osalevad kõik tšekid, mis
                  on registreeritud ajavahemikus 29.01.2024 kell 00:00:00 kuni
                  4.02.2024 kell 23:59:59.
                </li>
                <li>
                  12.02.2024 kell 12:00, mil loositakse üks Sony PlayStation 5
                  mängukonsool. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 5.02.2024 kell 00:00:00 kuni
                  11.02.2024 kell 23:59:59.
                </li>
                <li>
                  19.02.2023 kell 12:00, mil loositakse üks Sony PlayStation 5
                  mängukonsool. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 12.02.2024 kell 00:00:00 kuni
                  18.02.2024 kell 23:59:59.
                </li>
                <li>
                  26.02.2024 kell 12:00, mil loositakse välja üks Sony
                  PlayStation 5 mängukonsool. Loosis osalevad kõik tšekid, mis
                  on registreeritud ajavahemikus 19.02.2024 kell 00:00:00 kuni
                  25.02.2024 kell 23:59:59.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Osalejate registreerimise kampaanias ja auhindade väljaloosimise tagab
          ja kindlustab kampaania korraldaja volitatud esindaja Direct Messenger
          OÜ, kes ühtlasi võtab võitjatega ühendust.
        </li>
        <li>
          <strong>Isikuandmete töötlemise kord</strong>
          <ol>
            <li>
              Kampaania käigus osaleja poolt esitatud isikuandmed on kaitstud ja
              PepsiCo Eesti AS töötleb neid vastavalt Eesti Vabariigis
              kehtivatele seadustele kampaania läbiviimise eesmärgil.
            </li>
            <li>
              Kampaanias osaleja annab PepsiCo Eesti AS-le nõusoleku töödelda
              esitatud andmeid järgnevatel eesmärkidel:
              <ol>
                <li>kampaania tulemuste kohta kokkuvõtete tegemiseks;</li>
                <li>
                  PepsiCo Eesti AS poolt kampaaniaga seotud koostööpartneritele
                  info edastamiseks;
                </li>
                <li>võitjate loosimiseks ja nende teavitamiseks;</li>
                <li>võitja nime avaldamiseks kampaania veebilehel.</li>
              </ol>
            </li>
            <li>
              Kampaanias osalejatel on õigus saada lisainfot PepsiCo Eesti AS
              poolt isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
              aadressile:{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                <u>pepsico.eesti@pepsico.com</u>
              </a>
            </li>
            <li>
              Isikuandmete vastutav töötleja on PepsiCo Eesti AS, registrikood
              10438708, Tuglase 14, 51006 Tartu, Eesti, tel 738 6600,{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                <u>pepsico.eesti@pepsico.com</u>
              </a>
            </li>
            <li>
              Kogutud andmeid säilitatakse 1 (üks) kuu alates kampaania
              lõpukuupäevast ehk kuni 25.03.2024.
            </li>
          </ol>
        </li>
        <li>
          Võitjate nimed avalikustatakse hiljemalt punktis 8.2. mainitud
          kuupäevadest kolme tööpäeva jooksul veebilehel{' '}
          <u>
            <a href="http://www.cheetos.ee/">www.cheetos.ee</a>.
          </u>
        </li>
        <li>
          Võitjate nimed on veebilehel üleval 2 (kaks) nädalat peale kampaania
          lõppu ehk kuni 10.03.2024.
        </li>
        <li>
          Võitjatega võtab ühendust Direct Messenger OÜ esindaja
          registreerimisvormis märgitud e-posti või telefoni teel, et leppida
          kokku auhinna üleandmise aeg.
        </li>
        <li>
          Võidetud auhinna saab kätte Direct Messenger OÜ kontorist (aadressil
          Mustamäe tee 44/1, Tallinn). Auhinna kättesaamiseks peab võitja
          esitama oma isikut tõendava dokumendi (pass, ID-kaart, juhiluba) ning{' '}
          <strong>registreeritud ostutšeki.</strong>
          <ol>
            <li>
              Kui auhinna võitja ei esita isikut tõendavat dokumenti ja/või
              ostutšekki või kui dokumentidel toodud andmed erinevad
              registreerimisvormil toodust, ei ole võitjal õigust auhinda saada.
            </li>
            <li>
              Kui auhinna võitja ei ole veel täisealine, võib tema auhinna vastu
              võtta tema seaduslik esindaja, esitades võitja ja tema enda isikut
              tõendava dokumendi (pass, ID-kaart, juhiluba) ning võitnud
              ostutšeki.
            </li>
          </ol>
        </li>
        <li>
          Auhinna üleandmisel allkirjastavad võitja ja kampaania korraldaja
          volitatud Direct Messenger OÜ esindaja auhinna üleandmise-vastuvõtmise
          akti. Pärast akti allkirjastamist ei ole võitjal õigust kampaania
          korraldajatele auhinna suhtes nõudeid ega kaebusi esitada.
        </li>
        <li>
          Kampaania korraldajad ei vastuta auhinna võitjale auhinna kasutamise
          käigus tekkinud kahju eest.
        </li>
        <li>Auhinda ei saa vahetada teise auhinna ega raha vastu.</li>
        <li>
          Auhinna kättesaamisega seotud lisakulusid, mida ei ole käesolevates
          eeskirjades reguleeritud (nt transpordi- või helistamiskulud),
          võitjale ei kompenseerita.
        </li>
        <li>
          <strong>
            Auhind, mida võitjale välja ei anta või mille võitja jätab välja
            võtmata, loositakse uuesti välja. Kui auhinna võitjaga ei saada
            kontakti 5 tööpäeva jooksul, loositakse uus võitja.{' '}
          </strong>
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osalejad käesolevaid eeskirju ei järgi.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osaleja jääb loosimisest välja, kui auhinda eeskirjade mittejärgimise
          tõttu välja ei anta, kui kampaanias osaleja esitatud andmed on väärad
          või ebatäpsed või kui võitjatega ei ole võimalik ühendust saada
          kampaania korraldajatest mittesõltuvatel põhjustel.
        </li>
        <li>
          Vääramatu jõu asjaolude esinemisel on kampaania korraldajal õigus
          kampaania ühepoolselt katkestada. Sellisel juhul on kampaania
          korraldajal kohustus teavitada Eesti Tarbijakaitseametit ja edastada
          teade kampaania peatamisest ka massimeedia kaudu.
        </li>
        <li>
          Kõik nõuded ja kaebused seoses kampaania korralduse ja/või
          läbiviimisega tuleb esitada kirjalikult kampaania korraldajale Pepsico
          Eesti AS-le 3. märtsiks 2024 aadressil Tuglase 14, Tartu 51006 või
          e-kirja teel aadressile{' '}
          <a href="mailto:pepsico.eesti@pepsico.com">
            <u>pepsico.eesti@pepsico.com</u>
          </a>
          , märkides märksõnaks „Cheetos 2024“ ja esitades oma põhjendatud
          kaebuse ning lisades kaebuses viidatud dokumendid või nende koopiad.
          <ol>
            <li>
              Kaebusi, mis esitatakse pärast 3.03.2024, ei võeta menetlusse.
            </li>
            <li>
              Kampaania korraldaja vastab esitatud kaebusele kirjalikult
              viieteistkümne päeva jooksul pärast selle kättesaamist. Kui
              kampaania korraldaja peab kaebust õigustatuks, teavitavad nad
              kaebuse esitajat kaebuse rahuldamise protsessist ja kuupäevast.
              Kui kaebuse esitaja ei ole saadud vastusega rahul, on tal õigus
              seadusega ettenähtud korras oma õigusi ja huve kaitsta.
            </li>
            <li>
              Kaebuse esitamise kuupäevaks loetakse selle postitamise kuupäeva.
            </li>
            <li>
              Kaebusele tuleb alati lisada loosis osaleja eesnimi ja
              perekonnanimi.
            </li>
          </ol>
        </li>
        <li>
          Käesolevates eeskirjades on sätestatud nii kampaania korraldaja, tema
          esindajate kui osalejate õigused ja kohustused.
        </li>
        <li>
          Kampaania korraldaja hoiatab, et reklaammaterjalides toodud teave
          kannab ainult teavitavat eesmärki.
        </li>
        <li>
          Kampaanias ei tohi osaleda PepsiCo Eesti AS, Direct Messenger OÜ ning
          Zavod OÜ töötajad ja perekonnaliikmed.
        </li>
        <li>
          Kampaania korraldaja töötleb andmeid vastavalt Euroopa isikuandmete
          kaitse üldmäärusele. Lähemalt:
          <a href="https://pepsicoprivacypolicy.com/et?domain=pepsico.ee">
            https://pepsicoprivacypolicy.com/et?domain=pepsico.ee
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Et;
