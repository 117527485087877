import React, { ReactElement } from 'react';
import './Rules.scss';

const Lt: React.FC = (): ReactElement => {
  return (
    <div className="Lt rules-wrapper">
      <h2> „Cheetos PlayStation“ kampanija 2024</h2>
      <ol>
        <li>
          Vartotojų kampanija „Cheetos PlayStation Campaign 2024“ – tai akcija
          Lietuvoje, kurią organizuoja „Cheetos“ kukurūzų traškučių platintojas
          Lietuvoje – UAB „Lithuanian Snacks“ (toliau – „kampanijos
          organizatorius“), įmonės kodas 111562753, Žalgirio g. 94, LT-09300,
          Vilnius. Loteriją organizuoja UAB „Sorbum LT“, įmonės kodas 125767647,
          J. Jasinskio g. 16G, LT-03163 Vilnius.
        </li>
        <li>
          Kampanijos teritorija – visos Lietuvos Respublikos teritorijoje
          esančios parduotuvės, kuriose yra parduodami kampanijos produktai.
        </li>
        <li>
          Kampanijoje gali dalyvauti įvairaus amžiaus Lietuvos gyventojai.
        </li>
        <li>
          Kampanija vykdoma nuo 2024 m. sausio 15 d. iki vasario 25 d.
          (imtinai).
        </li>
        <li>Kampanijoje dalyvauja visi „Cheetos“ produktai.</li>
        <li>
          Mažmeninėje prekyboje parduodamų ir kampanijoje dalyvaujančių produktų
          kiekis nėra ribotas. Atsižvelgiama į pagrįstą paklausą. Prieš arba po
          šių sąlygų 4 punkte nurodytos datos parduotuvėse įsigyti „Cheetos“
          produktai kampanijoje nedalyvauja.
        </li>
        <li>
          <strong>Kampanijos principas.</strong>
          <ol>
            <li>
              <strong>
                Vartotojas, norėdamas dalyvauti kampanijoje, turi įsigyti bent
                vieną „Cheetos“ produktą ir užregistruoti pirkimo čekį interneto
                svetainėje{' '}
              </strong>
              <u>
                <a href="http://www.cheetos.lt/">
                  <strong>www.cheetos.lt</strong>
                </a>
              </u>
              <strong>
                . Bus lošiamos 6 (šešios) „Sony PlayStation 5“ žaidimų konsolės.
              </strong>
            </li>
            <li>
              Registruojant pirkimo čekį interneto svetainėje{' '}
              <u>
                <a href="http://www.cheetos.lt/">www.cheetos.lt</a>
              </u>
              , reikia nurodyti lošime dalyvaujančio asmens vardą, pavardę,
              pirkimo čekio numerį, telefono numerį ir el. paštą. Registracija
              svetainėje{' '}
              <u>
                <a href="http://www.cheetos.lt/">www.cheetos.lt</a>
              </u>
              . yra nemokama. Jeigu dalyviui nepavyksta užregistruoti pirkimo
              čekio numerio, puslapyje pasirodo klaidos pranešimas.
            </li>
            <li>
              Nepilnametis dalyvis gali registruotis interneto svetainėje{' '}
              <u>
                <a href="/C:/Users/Ainas/Downloads/cheetos.lt">
                  www.cheetos.lt
                </a>
              </u>
              . gavęs savo teisėto atstovo sutikimą, nurodydamas atstovo el.
              pašto adresą ir asmens duomenis.
            </li>
            <li>
              Registruodamas pirkimo čekyje esantį numerį pagal šių taisyklių
              7.2. punktą, kampanijos dalyvis prisiima atsakomybę už teisingų
              asmens duomenų pateikimą ir padarytas klaidas.
            </li>
            <li>
              Kampanijos dalyvis privalo registruotą pirkimo čekį saugoti iki
              2024-03-25, kad galėtų įrodyti kampanijoje dalyvaujančio produkto
              pirkimo faktą.
            </li>
            <li>
              Vieną pirkimo čekio numerį per visą kampanijos laiką galima
              registruoti vieną kartą.
            </li>
            <li>
              Vienas dalyvis gali registruotis keletą kartų, jeigu kelis kartus
              perka kampanijoje dalyvaujančius produktus ir kiekvieną kartą
              registruoja naują čekio numerį.
              <ol>
                <li>
                  Jeigu tas pats asmuo bando vieną pirkimo čekį registruoti
                  keletą kartų, lošime dalyvaus tik pirmąjį kartą teisingai
                  įregistruotas čekis – visi pasikartojantys čekiai bus
                  anuliuoti.
                </li>
                <li>
                  Čekis, laimėjęs tarpinį prizą, didžiojo prizo lošime antrą
                  kartą nedalyvaus. Vienas čekis gali laimėti vieną kartą.
                </li>
                <li>
                  Tas pats dalyvis gali laimėti keletą kartų, jei užregistruoja
                  kelis skirtingus čekius.
                </li>
              </ol>
            </li>
            <li>
              Užregistravus pirkimo čekį, jis bus įtrauktas į prizų lošimą
              Lošimų datos nurodytos 8.2. punkte.
            </li>
          </ol>
        </li>
        <li>
          <strong>Prizinis fondas</strong>
          <ol>
            <li>Prizinio fondo vertė yra 3 534 eurai.</li>
            <li>
              Prizai yra šešios „Sony PlayStation 5“ žaidimų konsolės (vienos
              konsolės vertė – 589 EUR).Prizų lošimai vyksta kartą per savaitę
              šiomis dienomis:
              <ol>
                <li>
                  2024-01-22 12.00 val. bus išlošta viena „Sony PlayStation 5“
                  žaidimų konsolė. Lošime dalyvauja visi nuo 2024-01-15 00:00:00
                  val. iki 2024-01-21 23:59:59 val. registruoti čekiai.
                </li>
                <li>
                  2024-01-29 12:00 val. bus išlošta viena „Sony PlayStation 5“
                  žaidimų konsolė. Lošime dalyvauja visi nuo 2024-01-22 00:00:00
                  val. iki 2024-01-28 23:59:59 val. registruoti čekiai.
                </li>
                <li>
                  2024-02-05 12.00 val. bus išlošta viena „Sony PlayStation 5“
                  žaidimų konsolė. Lošime dalyvauja visi nuo 2024-01-29 00:00:00
                  val. iki 2024-02-04 23:59:59 val. registruoti čekiai.
                </li>
                <li>
                  2024-02-12 12.00 val. bus išlošta viena „Sony PlayStation 5“
                  žaidimų konsolė. Lošime dalyvauja visi nuo 2024-02-05 00:00:00
                  val. iki 2024-02-11 23:59:59 val. registruoti čekiai.
                </li>
                <li>
                  2023-02-19 12.00 val. bus išlošta viena „Sony PlayStation 5“
                  žaidimų konsolė. Lošime dalyvauja visi nuo 2024-02-12 00:00:00
                  val. iki 2024-02-18 23:59:59 val. registruoti čekiai.
                </li>
                <li>
                  2024-02-26 12.00 val. bus išlošta viena „Sony PlayStation 5“
                  žaidimų konsolė. Lošime dalyvauja visi nuo 2024-02-19 00:00:00
                  val. iki 2024-02-25 23:59:59 val. registruoti čekiai.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Dalyvių registraciją kampanijoje ir prizų lošimą užtikrins ir
          garantuos kampanijos organizatoriaus įgaliotasis atstovas UAB „Sorbum
          LT“, kuris taip pat susisieks su nugalėtojais.
        </li>
        <li>
          <strong>Asmens duomenų tvarkymo tvarka</strong>
          <ol>
            <li>
              Asmens duomenys, kuriuos dalyvis teikia kampanijos metu, yra
              saugomi ir tvarkomi UAB „Lithuanian Snacks“ kampanijos tikslais,
              vadovaujantis Lietuvos Respublikos įstatymais.
            </li>
            <li>
              Kampanijos dalyvis sutinka, kad UAB „Lithuanian Snacks“ tvarkytų
              pateiktus duomenis šiais tikslais:
              <ol>
                <li>apibendrinti kampanijos rezultatus;</li>
                <li>
                  perduoti informaciją UAB „Lithuanian Snacks“partneriams;
                </li>
                <li>išaiškinti loterijos laimėtojus ir juos informuoti;</li>
                <li>
                  skelbti laimėtojo vardą ir pavardę kampanijos interneto
                  svetainėje.
                </li>
              </ol>
            </li>
            <li>
              Kampanijos dalyviai turi teisę gauti papildomą informaciją apie
              UAB „Lithuanian Snacks“ tvarkomus asmens duomenis. Norėdami gauti
              informacijos, rašykite adresu:{' '}
              <a href="mailto:infoLT@pepsico.com">
                <u>infoLT@pepsico.com</u>
              </a>
            </li>
            <li>
              Asmens duomenų valdytojas yra UAB „Lithuanian Snacks“, įmonės
              kodas 111562753, adresas: Žalgirio g. 94, LT-09300, Vilnius,{' '}
              <a href="mailto:infoLT@pepsico.com">
                <u>infoLT@pepsico.com</u>
              </a>
              .
            </li>
            <li>
              Surinkti duomenys yra saugomi vieną (1) mėnesį nuo kampanijos
              pabaigos datos arba iki 2024-03-25.
            </li>
          </ol>
        </li>
        <li>
          Laimėtojų vardai bus paskelbti ne vėliau kaip 8.2. punkte nurodytomis
          datomis per tris darbo dienas svetainėje{' '}
          <u>
            <a href="http://www.cheetos.lt/">www.cheetos.lt</a>
          </u>
          .
        </li>
        <li>
          Laimėtojų vardai ir pavardės interneto svetainėje bus prieinami 2
          (dvi) savaites pasibaigus kampanijai, t. y. iki 2024-03-10
        </li>
        <li>
          Su laimėtojais susisieks UAB „Sorbum LT“ atstovas registracijos
          formoje nurodytu el. pašto adresu arba telefonu ir susitars dėl prizo
          atsiėmimo laiko.
        </li>
        <li>
          Laimėtą prizą galima UAB „Sorbum LT“ biure (J. Jasinskio g. 16G,
          LT-03163 Vilnius). Norėdamas atsiimti prizą, laimėtojas privalo
          pateikti savo asmens tapatybę patvirtinantį dokumentą (pasą, asmens
          tapatybės kortelę, vairuotojo pažymėjimą) ir{' '}
          <strong>užregistruotą pirkimo čekį.</strong>
          <ol>
            <li>
              Jeigu laimėtojas nepateikia asmens tapatybę patvirtinančio
              dokumento arba jeigu dokumentuose nurodyti duomenys skiriasi nuo
              registracijos formoje nurodytų, laimėtojas neturi teisės gauti
              prizo.
            </li>
            <li>
              Jei laimėtojas nėra pilnametis, jo prizą gali atsiimti jo atstovas
              pagal įstatymą, pateikdamas laimėtojo ir savo asmens tapatybę
              patvirtinantį dokumentą (pasą, asmens tapatybės kortelę,
              vairuotojo pažymėjimą) ir laimėjusį čekį.
            </li>
          </ol>
        </li>
        <li>
          Perduodant prizą, laimėtojas ir kampanijos organizatoriaus įgaliotasis
          atstovas „Sorbum LT“ pasirašo prizo perdavimo–priėmimo aktą. Po akto
          pasirašymo laimėtojas neturi teisės kampanijos organizatoriams
          pareikšti reikalavimų arba skundų.
        </li>
        <li>
          Kampanijos organizatoriai neatsako už bet kokią žalą, atsiradusią
          prizo naudojimo metu.
        </li>
        <li>Prizai nekeičiami į kitus prizus ar grynuosius pinigus.</li>
        <li>
          Su prizo atsiėmimu susijusios papildomos išlaidos, kurioms netaikomos
          šios taisyklės (pavyzdžiui, transporto ar skambinimo išlaidos),
          laimėtojui nekompensuojamos.
        </li>
        <li>
          <strong>
            Neatiduotas laimėtojui arba neatsiimtas laimėtojo prizas lošiamas iš
            naujo. Jeigu su prizo laimėtoju nepasiseka susisiekti per 5 darbo
            dienas, renkamas naujas laimėtojas.{' '}
          </strong>
        </li>
        <li>
          Kampanijos organizatorius neatsako, jeigu kampanijos dalyviai
          nesilaiko šių taisyklių.
        </li>
        <li>
          Kampanijos organizatorius neatsako, jeigu kampanijos dalyvis
          pašalinamas iš kampanijos ir prizas neperduodamas dėl kampanijos
          taisyklių nesilaikymo, jeigu kampanijos dalyvio pateikti duomenys yra
          klaidingi arba netikslūs, arba jeigu su dalyviu nepavyksta susisiekti
          dėl nuo organizatorių nepriklausančių priežasčių.
        </li>
        <li>
          Pasireiškus nenugalimos jėgos aplinkybėms, kampanijos organizatorius
          turi teisę kampaniją nutraukti vienašališkai. Tokiu atveju kampanijos
          organizatorius privalo informuoti Lietuvos vartotojų teisių apsaugos
          tarnybą ir žiniasklaidoje paskelbti informaciją apie kampanijos
          sustabdymą.
        </li>
        <li>
          Visos su kampanijos organizavimu ir (arba) vykdymu susijusios
          pretenzijos ir skundai turi būti teikiami raštu iki 2024 m. kovo 3 d.
          organizatoriui UAB „Lithuanian Snacks“ adresu Žalgirio g. 94,
          LT-09300, Vilnius arba el. paštu infoLT@pepsico.com, pridėjus pagrįstą
          skundą ir skundo dokumentų priedus arba jų kopijas.
          <ol>
            <li>Vėliau negu 2024-03-03 pateikti skundai nebus nagrinėjami.</li>
            <li>
              Kampanijos organizatorius į pateiktą skundą atsako raštu per
              penkiolika dienų nuo jo gavimo. Jeigu kampanijos organizatorius
              mano, kad skundas yra pagrįstas, jis skundo pareiškėją informuoja
              apie skundo patenkinimo eigą ir datą. Jei skundo pateikėjas yra
              nepatenkintas gautu atsakymu, jis turi teisę įstatymo nustatyta
              tvarka ginti savo teises ir interesus.
            </li>
            <li>Skundo pateikimo data yra laikoma jo išsiuntimo data.</li>
            <li>Skunde būtina nurodyti lošimo dalyvio vardą ir pavardę.</li>
          </ol>
        </li>
        <li>
          Šiose taisyklėse yra nurodytos kampanijos organizatoriaus, jo atstovų
          ir dalyvių teisės bei pareigos.
        </li>
        <li>
          Kampanijos organizatorius įspėja, kad reklaminėje medžiagoje pateikta
          informacija yra tik informacinio pobūdžio.
        </li>
        <li>
          Kampanijoje negali dalyvauti UAB „Lithuanian Snacks“ ir UAB „Sorbum
          LT“ darbuotojai bei jų šeimos nariai.
        </li>
        <li>
          Kampanijos organizatorius tvarko duomenis pagal Europos asmens duomenų
          apsaugos reglamento nuostatas. Daugiau informacijos:{' '}
          <a href="https://pepsicoprivacypolicy.com/lt?domain=pepsico.lt">
            <u>https://pepsicoprivacypolicy.com/lt?domain=pepsico.lt</u>
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Lt;
